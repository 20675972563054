.main
  display: flex
  justify-content: center
  align-items: center
  background-color: #2a2a2a
  padding: 20px

.product-container
  display: flex
  flex-direction: column
  align-items: center
  max-width: 400px
  background-color: #333
  border-radius: 15px
  padding: 20px
  margin-top: 30px
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4)
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out

  &:hover
    transform: translateY(-5px)
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5)

.product-image
  width: 100%
  height: auto
  border-radius: 10px
  margin-bottom: 20px
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5)

.product-details
  text-align: center
  color: #f1f0f0

.price
  font-size: 22px
  padding: 5px 0 15 px 0
  color: #7e4b9e
  font-weight: bold
  span
    font-weight: bold
    color: #f2f0f0

.buy-button
  background-color: #6a1b9a
  color: #f2f0f0
  padding: 12px 45px
  min-width: 250px
  font-size: 18px
  font-weight: bold
  border: none
  border-radius: 8px
  cursor: pointer
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3)
  transition: background-color 0.3s ease, transform 0.2s ease
  margin-top: 10px
  &:hover
    background-color: #581582

  &:active
    transform: scale(0.98)
    background-color: #581582

.form-button
  background-color: #6a1b9a
  color: #f2f0f0
  padding: 12px 45px
  font-size: 18px
  font-weight: bold
  min-width: 250px
  border: none
  border-radius: 8px
  cursor: pointer
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3)
  transition: background-color 0.3s ease, transform 0.2s ease
  &:hover
    background-color: #581582
    

  &:active
    transform: scale(0.98)
    background-color: #581582

.h2
  margin: 0
  font-size: 25px
  text-align: center
  color: #f1f0f0
  line-height: 1.2
  padding-bottom: 18px
  background: linear-gradient(90deg, #9b59b6, #8e44ad, #6a1b9a)
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent

.form
  display: flex
  flex-direction: column
  align-items: center
  padding: 20px
  background-color: #333
  border-radius: 15px
  margin-top: 30px
  color: #f1f0f0
  width: 100%
  max-width: 400px
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4)

.form-group
  margin-bottom: 20px

.form-group:last-of-type
  margin-bottom: 30px

.label-box
  display: flex
  justify-content: center
  
.label
  display: inline-block
  align-self: flex-start // Сместить влево
  margin-bottom: 5px
  font-size: 18px // Сделать текст меньше
  background-color: rgba(37, 35, 35, 0.7) // Сделать фон более прозрачным
  padding: 3px 10px // Уменьшить отступы внутри
  line-height: 1.2
  border-radius: 5px // Уменьшить скругление углов
  color: #f1f0f0 // Цвет текста
  margin-top: 5px // Немного отступ сверху для пространства


.input
  padding: 10px
  border-radius: 8px
  border: 1px solid #ccc
  width: 300px
  font-size: 18px
  transition: border 0.3s ease

  &:focus
    border: 1px solid #581582

.error
  padding-top: 3px
  color: #ff4d4f
  font-size: 14px
  text-align: center
  line-height: 1.1

.payment-container
  display: flex
  flex-direction: column
  align-items: center
  margin-top: 30px
  background-color: #2e2e2e
  padding: 30px 20px
  border-radius: 15px
  width: 100%
  max-width: 400px
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4)
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out

.payment-methods
  display: flex
  flex-direction: column
  width: 100%
  margin-bottom: 20px

.payment-method
  display: flex
  justify-content: space-between
  align-items: center
  background-color: #3a3a3a
  color: #f1f0f0
  padding: 15px 20px
  margin: 8px 0
  border-radius: 12px
  font-size: 18px
  cursor: pointer
  transition: all 0.3s ease
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2)
  border: 2px solid rgba(0, 0, 0, 0.2)

  &:active
    transform: scale(0.98)

.selected
  background-color: #2c2b2b
  border: 2px solid #581582
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4)

.payment-method-text
  font-size: 18px
  color: #f1f0f0

.payment-method-icon
  width: 32px
  height: 32px
  transition: transform 0.3s ease

.payment-method-icon-box
  padding: 5px
  background-color: #f5f5f5
  border-radius: 50%
  display: flex
  justify-content: center
  align-items: center
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2)
  transition: transform 0.3s ease, background-color 0.3s ease

.selected .payment-method-icon-box
  transform: scale(1.1)
    

.warning
  color: #ffeb3b
  margin-bottom: 10px
  font-size: 16px
  text-align: center
  line-height: 1.2
  background: rgba(255, 235, 59, 0.1)
  padding: 10px
  border-radius: 8px
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3)

.payment-price
  color: #f1f0f0
  font-size: 18px
  text-align: center
  line-height: 1.2
  margin-bottom: 10px
  font-weight: bold

.h1
  margin: 0
  font-size: 25px
  text-align: center
  color: #f1f0f0
  line-height: 1.3
  padding-bottom: 10px
  border-bottom: 2px solid #6a1b9a
  display: inline-block
  margin-bottom: 5px


.redirect-container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  background-color: #1e1e1e
  padding: 40px 30px
  margin-top: 30px
  border-radius: 20px
  width: 100%
  max-width: 450px
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5)
  text-align: center
  color: #f9f9f9
  margin-top: 30px
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out

  &:hover
    transform: translateY(-5px)
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.6)

.redirect-text
  font-size: 19px
  margin-bottom: 25px
  color: #ffd700
  line-height: 1.4
  font-weight: 500

.redirect-buttons
  display: flex
  flex-direction: column
  align-items: center
  gap: 15px
  width: 100%
  margin-top: 25px

.redirect-button
  background: linear-gradient(90deg, #7b1fa2, #9c27b0)
  color: #ffffff
  padding: 15px 50px
  font-size: 18px
  font-weight: bold
  border: none
  border-radius: 10px
  cursor: pointer
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4)
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease
  text-align: center
  width: 100%
  max-width: 280px

  &:hover
    background: linear-gradient(90deg, #6a1b9a, #8e24aa)
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5)

  &:active
    transform: scale(0.97)
    background: linear-gradient(90deg, #5e1589, #7b1fa2)

  &:disabled
    background: #999
    cursor: not-allowed
    box-shadow: none