.main
  padding: 20px
  background-color: #2a2a2a
  display: flex
  flex-direction: column
  align-items: center

.h1
  margin: 0
  font-size: 30px
  text-align: center
  color: #f1f0f0
  line-height: 1
  background: linear-gradient(90deg, #9b59b6, #8e44ad, #6a1b9a)
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent

.product-list
  display: grid
  grid-template-columns: 1fr / 1fr
  gap: 10px
  max-width: 1200px
  width: 100%
  padding-top: 10px
