.game-card
  display: flex
  flex-direction: column
  align-items: center
  transition: transform 0.2s ease, box-shadow 0.2s ease
  width: 150px  // Фиксированная ширина для одинакового размера всех карточек
  text-align: center
  margin-top: 10px

  &:hover
    transform: translateY(-5px)

  img
    width: 100%
    height: auto
    border-radius: 33px  // Делаем изображение закругленным
    object-fit: cover
    margin-bottom: 5px

  h2
    font-size: 18px
    color: #ffffff
    text-align: center
    line-height: 1
    margin: 0
    padding-top: 5px

.header
  width: 100%
@media (max-width: 500px)
  .game-card
    width: 45vw
    img
      width: 40vw

@media (max-width: 280px)
  .game-card
    width: 90vw
    img
      width: 80vw
      
  