.main
  max-width: 100vw
  min-height: 100vh
  background: linear-gradient(135deg, #2a2a2a, #1a1a1a)
  padding: 10px 20px
  display: flex
  flex-direction: column
  align-items: center
  position: relative
  overflow: hidden

.h1
  margin: 0
  font-size: 28px
  text-align: center
  color: #f4f4f4
  line-height: 1
  padding: 10px 10px
  font-weight: bold
  letter-spacing: 1.5px
  text-shadow: 0 3px 10px rgba(0, 0, 0, 0.4)
  z-index: 2
  position: relative
  background: linear-gradient(90deg, #9b59b6, #8e44ad, #6a1b9a)
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent

.header-background
  position: absolute
  top: 0
  left: 0
  width: 100vw
  height: 80px
  background: rgba(0, 0, 0, 0.3)
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4)
  z-index: 1

.header
  width: 100vw
  display: flex
  justify-content: space-between
  align-items: center
  position: relative
  padding: 10px 25px
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3)
  margin-bottom: 5px

.icon-perfil
  width: 45px
  height: 45px
  background: linear-gradient(135deg, #e8dfdf, #ffffff)
  border-radius: 50%
  padding: 5px
  border: 2px solid #9b59b6
  display: flex
  justify-content: center
  align-items: center
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2)
  transition: transform 0.3s ease, box-shadow 0.3s ease

  &:hover
    transform: scale(1.1)
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3)

.link-perfil
  text-decoration: none
  position: relative
  z-index: 2

.game-list
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr))
  gap: 20px
  justify-items: center
  padding-top: 10px
  width: 100%
  max-width: 1000px
  box-sizing: border-box
  z-index: 2
  position: relative

.game-card
  background-color: #333
  border-radius: 15px
  padding: 15px
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3)
  transition: transform 0.3s ease, box-shadow 0.3s ease
  cursor: pointer
  overflow: hidden
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  position: relative

  &:hover
    transform: translateY(-5px)
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4)

  img
    width: 100px
    height: 100px
    border-radius: 12px
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3)
    transition: transform 0.3s ease
    margin-bottom: 10px

    &:hover
      transform: scale(1.05)

  p
    margin: 0
    font-size: 16px
    color: #f1f0f0
    text-align: center
    font-weight: bold

.error-message
  background-color: #f44336
  color: #f1f0f0
  padding: 15px
  border-radius: 8px
  margin: 20px
  text-align: center
  font-size: 16px
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3)
  z-index: 2

@media (max-width: 360px)
  .game-list
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr))
  .header
    .h1
      font-size: 22px

@media (max-width: 330px)
  .main
    padding: 20px 10px
  .header
    .icon-perfil
      top: 15px
      right: 10px

@media (max-width: 280px)
  .game-list
    grid-template: 1fr / 1fr
