.main
  display: flex
  justify-content: center
  align-items: center
  min-height: 100vh
  background-color: #2a2a2a
  background: linear-gradient(135deg, #2a2a2a 0%, #1c1c1c 100%)
  padding: 15px 5px
  overflow: hidden
  position: relative

  // Анимация плавных всплывающих световых точек на фоне
  &::before
    content: ''
    position: absolute
    width: 200px
    height: 200px
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0) 70%)
    animation: moveLights 10s infinite ease-in-out
    border-radius: 50%
    top: -50px
    left: -50px

  &::after
    content: ''
    position: absolute
    width: 200px
    height: 200px
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0) 70%)
    animation: moveLights 10s infinite ease-in-out
    border-radius: 50%
    bottom: -50px
    right: -50px

  @keyframes moveLights
    0%, 100%
      transform: translateY(0) translateX(0)
    50%
      transform: translateY(20px) translateX(20px)

.profile-container
  max-width: 100%
  background-color: #333
  border-radius: 15px
  padding: 20px
  color: #f1f0f0
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5)
  margin: 0 10px
  position: relative
  overflow: hidden

  &::before
    content: ''
    position: absolute
    top: 0
    left: 0
    right: 0
    height: 5px
    background: linear-gradient(90deg, #8e44ad, #9b59b6, #d81b60)
    animation: gradientMove 5s linear infinite

  @keyframes gradientMove
    0%
      background-position: 0% 0
    100%
      background-position: 100% 0

.h1
  text-align: center
  color: #8e44ad
  font-size: 26px
  margin-bottom: 15px
  font-weight: bold
  letter-spacing: 1px
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.3)
  background: linear-gradient(90deg, #9b59b6, #8e44ad, #6a1b9a)
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent

.h2
  text-align: center
  color: #f1f0f0
  font-size: 22px
  margin-bottom: 15px
  position: relative

.order-list
  display: flex
  flex-direction: column
  gap: 16px

.order-card
  background-color: #444
  padding: 20px
  border-radius: 10px
  color: #f1f0f0
  transition: transform 0.3s ease, box-shadow 0.3s ease
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3)
  position: relative
  overflow: hidden

  &:hover
    transform: translateY(-5px)
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4)

  &::before
    content: ''
    position: absolute
    top: 0
    left: 0
    bottom: 0
    width: 8px
    background: linear-gradient(180deg, #6a1b9a, #8e44ad, #c2185b)
    border-top-left-radius: 8px
    border-bottom-left-radius: 8px

  p
    margin: 6px 0
    font-size: 16px

.order-header
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 10px

.order-number
  font-size: 16px
  font-weight: bold
  color: #8e44ad
  span
    font-size: 17px

.order-status
  font-size: 15px
  padding: 8px 12px
  border-radius: 10px
  color: #fff
  display: block
  text-align: center
  min-width: 110px

  &.status-pending
    background-color: #ff9800

  &.status-inProgress
    background-color: #03a9f4

  &.status-completed
    background-color: #4caf50

  &.status-fail
    background-color: #f44336

  &.status-canceled
    background-color: #9e9e9e

.error
  color: #ff4d4f
  text-align: center
  margin-top: 15px

.no-orders
  color: #f1f0f0
  font-size: 18px
  text-align: center
  background: #444
  border-radius: 8px
  padding: 10px
  margin-top: 15px
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3)
