/* установка цвета фона для body */
body {
  background-color: #2a2a2a
}

html {
  min-height: 100vh;
  background-color: #2a2a2a
}

.loading {
  min-height: 100%;
  background: rgb(92, 24, 24);
}

/* удаление подчеркивания для ссылок */
body a {
  text-decoration: none;
}

body a:hover {
  text-decoration: none;
}

/* удаление отступов для абзацев */
body p {
  margin: 0;
}

/* удаление отступов и маркеров для списков */
body ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/* удаление границы для кнопок */
body button {
  border: none;
}

/* установка значения курсора по умолчанию для некоторых элементов */
body h1, body h2, body h3, body h4, body h5, body h6, body p, body div {
  cursor: default;
  margin: 0;
}

/* блок с позицией относительно */
.abox {
  position: relative;
}

/* фон для исправления бага во время адаптивного режима */
.fixbg {
  background-color: #40152C;
}

/* блок, находящийся поверх других элементов */
.zibox {
  position: relative;
  z-index: 1;
}

/* контейнер с гибкими столбцами */
.fcolumn {
  display: flex;
  flex-direction: column;
}

/* контейнер с гибкими строками */
.frow {
  display: flex;
  flex-direction: row;
}

/* специальный блок для работы с сеткой */
.gbox {
  display: grid;
}


*::-webkit-scrollbar {
  width: 8px; /* Уменьшаем ширину скроллбара */
}

*::-webkit-scrollbar-track {
  background-color: #2a2a2a; /* Серый фон трека, соответствующий общему фону приложения */
}

*::-webkit-scrollbar-thumb {
  background-color: #1abc9c; /* Основной цвет скроллбара - мягкий зеленый, который хорошо сочетается с серым */
  border-radius: 8px;
  border: 2px solid #2a2a2a; /* Добавляем отступ вокруг скроллбара, чтобы он выглядел "встроенным" */
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #16a085; /* Цвет при наведении - чуть более темный зеленый */
}

/* Для поддержки прокрутки во всех браузерах */
* {
  scrollbar-width: thin; /* Firefox поддержка - делаем узкий скроллбар */
  scrollbar-color: #1abc9c #2a2a2a; /* Цветовая схема для Firefox */
}

html {
  scroll-behavior: smooth;
}
