.product-card
  background-color: #333 // Цвет фона как в примере
  border-radius: 15px
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
  width: 100% // Ширина карты будет адаптивной
  max-width: 320px // Максимальная ширина карты
  padding: 10px
  text-align: left
  transition: transform 0.2s ease, box-shadow 0.2s ease
  display: flex
  flex-direction: column
  margin: 0 auto
  &:hover
    transform: translateY(-5px)
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2)

.product-image
  max-width: 100%
  height: auto
  border-radius: 25px
  margin-bottom: 7px

.product-info
  display: flex
  justify-content: space-between
  align-items: start
  width: 100%

.product-text
  display: flex
  flex-direction: column

.product-name
  font-size: 16px
  color: #fff // Белый цвет текста
  line-height: 1.1

.product-game
  font-size: 11px
  color: #aaa // Светло-серый цвет текста

.product-price
  min-width: 100px
  text-align: top
  font-size: 16px
  color: #fff // Белый цвет текста
  margin-left: auto
  text-align: right
  line-height: 1.1
