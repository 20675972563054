@keyframes gradientBackground
  0%
    background-position: 0% 50%
  50%
    background-position: 100% 50%
  100%
    background-position: 0% 50%

.container
  display: flex
  justify-content: center
  align-items: center
  height: 100vh
  background: linear-gradient(270deg, #ffffff, #e0e0ff)
  background-size: 400% 400%
  animation: gradientBackground 10s ease infinite
  padding: 20px
  box-sizing: border-box

.messageBox
  background-color: #ffffff
  border-radius: 12px
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
  padding: 20px
  text-align: center
  max-width: 400px
  width: 100%
  transition: all 0.3s ease

.message
  font-size: 28px
  font-weight: bold
  color: #333333
  margin: 0

  // Адаптация под маленькие экраны
  @media (max-width: 768px)
    padding: 20px

  @media (max-width: 480px)
    padding: 15px
