.container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  min-height: 100vh
  background: linear-gradient(to right, #e3f2fd, #fff3e0)
  padding: 20px
  font-family: 'Helvetica Neue', sans-serif

h1
  font-size: 2.5rem
  margin-bottom: 40px
  color: #333
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2)

.products
  display: flex
  justify-content: space-around
  width: 100%
  max-width: 1200px
  margin-bottom: 40px
  flex-wrap: wrap

.product
  display: flex
  flex-direction: column
  align-items: center
  background: #ffffff
  border-radius: 15px
  padding: 30px
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15)
  transition: transform 0.3s ease
  width: 180px
  text-align: center
  margin: 10px

.product:hover
  transform: translateY(-10px)

h2
  font-size: 1.8rem
  margin-bottom: 10px
  color: #00796b

.price
  font-size: 1.2rem
  margin-bottom: 20px
  color: #ff7043

.buyButton
  padding: 12px 25px
  background-color: #007bff
  color: white
  font-size: 1rem
  border: none
  border-radius: 8px
  cursor: pointer
  transition: background-color 0.3s ease, box-shadow 0.3s ease

.buyButton:hover
  background-color: #0056b3
  box-shadow: 0 4px 8px rgba(0, 91, 187, 0.4)

.footer
  margin-top: 40px
  font-size: 1.1rem
  color: #666
  text-align: center

a
  color: #007bff
  text-decoration: none
  font-weight: bold

a:hover
  text-decoration: underline

.modal
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, 0.5)
  display: flex
  justify-content: center
  align-items: center

.modalContent
  background-color: #fff
  padding: 30px
  border-radius: 10px
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2)
  text-align: center

.emailInput
  width: 100%
  padding: 10px
  margin-bottom: 20px
  border: 1px solid #ccc
  border-radius: 5px
  font-size: 1rem

.paymentMethod
  margin-bottom: 20px

.submitButton
  padding: 10px 20px
  background-color: #007bff
  color: white
  border: none
  border-radius: 5px
  cursor: pointer
  transition: background-color 0.3s ease

.submitButton:hover
  background-color: #0056b3

.closeButton
  margin-top: 10px
  padding: 8px 16px
  background-color: #ff7043
  color: white
  border: none
  border-radius: 5px
  cursor: pointer

.closeButton:hover
  background-color: #e64a19
