.spinner-overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: linear-gradient(135deg, #2a2a2a, #1a1a1a)
  display: flex
  justify-content: center
  align-items: center
  z-index: 1000

.spinner-container
  display: flex
  justify-content: center
  align-items: center

.spinner
  border: 4px solid rgba(255, 255, 255, 0.2)
  border-top-color: #8e44ad // Основной цвет спиннера (фиолетовый)
  border-right-color: #9b59b6 // Второй цвет для создания градиентного эффекта
  border-bottom-color: #2980b9 // Третий цвет для многоцветного эффекта
  border-left-color: #8e44ad // Четвертый цвет для симметрии
  border-radius: 50%
  width: 60px // Немного увеличим размер
  height: 60px
  animation: spin 1s linear infinite
  box-shadow: 0 0 20px rgba(142, 68, 173, 0.5) // Светящаяся тень вокруг спиннера

@keyframes spin
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
